@charset "utf-8";
@import "../../../node_modules/bulma/sass/utilities/initial-variables.sass";

.menu {
  height: 100%;
  padding-top: $size-6;
  padding-bottom: $size-6;
  background-color: $grey-light;
}

.menu-label {
  padding-left: $size-7;
  font-weight: $weight-bold;
}

.menu-list {
  padding-left: 0;

  a {
    text-indent: $size-7;

    &.is-active {
      font-weight: $weight-bold;
    }
  }

}

.to_dashboard {
  margin-top: $size-1;
}

$menu-label-font-size: $size-6;
$menu-label-color: $white;
$menu-item-color: $menu-label-color;
$menu-item-hover-color: $menu-item-color;
$menu-item-active-background-color: $grey-dark;
$menu-item-hover-background-color: $menu-item-active-background-color;
@import "../../../node_modules/bulma/bulma.sass";