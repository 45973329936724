@charset "utf-8";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/components/navbar.sass";

.full_height {
  min-height: calc(100vh - #{$navbar-height});
}

.news {
  &_head {
    .status {
      width: 8em;
      height: $navbar-height;
    }

    .expired {
      width: 10em;
    }
  }

  tr.closed {
    background-color: $grey-light;
  }
}

.pagination [role=button] {
  background-color: white;
}

@import "../../../node_modules/bulma/sass/components/navbar.sass";