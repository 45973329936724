@charset "utf-8";

@import "../../../../node_modules/bulma/sass/utilities/_all.sass";

.calender {
  height: calc((100vw - (#{$size-4} * 2)) * (9 / 16));

  iframe {
    width: 100%;
    height: 100%;
  }
}