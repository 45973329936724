@charset "utf-8";
@import "../../../node_modules/bulma/sass/utilities/_all.sass";

.google_search {
  margin-top: $size-1;

  &_controller {
    width: 100%;
  }
}

.news_continaer {
  margin-top: $size-4;
  padding: $size-3;
  border-radius: 1em;
}

.news_list {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.custom_messages {
  border: solid 1px $white-ter;
}

.modal_header_title {
  flex-shrink: initial !important;
  line-height: 1.5 !important;
}

.modal_body {
  white-space: pre-line;
}

@import "../../../node_modules/bulma/sass/components/modal.sass"