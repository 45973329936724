@charset "utf-8";

$base-color: rgba(0, 0, 0, .4);
$shadow-color: rgba(0, 0, 0, .07);

.loading,
.loading:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loading {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid $shadow-color;
  border-right: 1.1em solid $shadow-color;
  border-bottom: 1.1em solid $shadow-color;
  border-left: 1.1em solid $base-color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 .9s infinite linear;
  animation: load8 .9s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}