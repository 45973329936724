@charset "utf-8";

@import "../../../../node_modules/bulma/sass/utilities/_all.sass";

input.short_input {
  width: 45em;
}

.color_select {
  display: inline-block;
  overflow: hidden;
  width: $size-3;
  height: $size-3;
  border: solid 1px $white;
  border-radius: 3px;
  text-indent: -10em;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0;
  }

  &.is-selected {
    border-color: red;
  }
}

.group_header {
  svg {
    visibility: hidden;
    cursor: pointer;
  }

  &:hover svg {
    visibility: visible;
  }
}

.group_name {
  margin-right: $size-6;
}

.image {
  overflow: hidden;

  &.is-64x64 img {
    width: 64px;
    height: 64px;
  }

  img {
    object-fit: cover;
  }
}

@import "../../../../node_modules/bulma/bulma.sass";
// @import "../../../../node_modules/bulma/sass/form/input-textarea.sass";